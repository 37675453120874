<template>
  <div class="address-page">
    <headerBar :title="'银行卡管理'"  :showArrow="true" @back="back"></headerBar>

   <div class="address-list">
      <div class="list small flex-center-between" v-for="(item, i) in addressList" :key="i" @click="toDetail(item)">
        <div class="icon flex-column-center">
          <img :src="cardUrl" alt="">
          <span>中国银行</span>
        </div>
        <div class="card">
          <div>张三</div>
          <div>1293192038091238102381</div>
        </div>
        <div class="arrow">
          <img :src="arrowUrl" alt="">
        </div>
      </div>

      <div class="empty-list" v-if="addressList.length == 0">请绑定银行卡</div>

      <div class="add-line flex-center" @click="toAdd">
        <img :src="addUrl" alt="">
        <span>添加银行卡</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      addUrl: require('../../assets/imgs/icon-add.png'),
      arrowUrl: require('../../assets/imgs/icon-arrow-right.png'),
      cardUrl: require('../../assets/imgs/icon-card.png'),
      addressList: [{}, {}, {}],
    }
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.$router.push({
        name: 'bindCard'
      })
    },
    toDetail(data) {
      this.$router.push({
        name: 'bankDetail',
        query: {
          id: data.id
        }
      })
    }
  }
}
</script>